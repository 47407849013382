.aboutPage {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 40px;
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle at center, rgba(50, 30, 15, 0.2), transparent 60%);
        pointer-events: none;
    }
    
    & .about-container {
        width: 100%;
        max-width: 1200px;
        
        & .about-content {
            display: flex;
            flex-direction: column;
            gap: 60px;
            
            & .about-header {
                display: flex;
                flex-direction: column;
                gap: 40px;
                
                & .title-container {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 20px;
                    
                    & .about-title {
                        font-size: 3em;
                        font-weight: 300;
                        background: linear-gradient(135deg, #f3cea1, #ffffff);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                        margin-bottom: 5px;
                    }
                    
                    & .title-underline {
                        position: relative;
                        height: 2px;
                        width: 80px;
                        background: linear-gradient(90deg, #FFD700, transparent);
                        margin-top: 10px;
                    }
                }
                
                & .profile-container {
                    display: flex;
                    gap: 40px;
                    align-items: center;
                    flex-wrap: wrap;
                    
                    & .profile-image-container {
                        position: relative;
                        width: 240px;
                        height: 240px;
                        
                        & .profile-image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                            z-index: 2;
                            position: relative;
                            border: 2px solid rgba(243, 206, 161, 0.3);
                        }
                        
                        & .image-decoration {
                            position: absolute;
                            top: -10px;
                            left: -10px;
                            width: 100%;
                            height: 100%;
                            border: 2px solid #FFD700;
                            border-radius: 10px;
                            z-index: 1;
                        }
                    }
                    
                    & .terminal-container {
                        flex: 1;
                        min-width: 300px;
                        border-radius: 10px;
                        overflow: hidden;
                        background: #0F1924;
                        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
                        border: 1px solid rgba(243, 206, 161, 0.2);
                        
                        & .terminal-header {
                            display: flex;
                            align-items: center;
                            padding: 10px 15px;
                            background: #162639;
                            
                            & .terminal-buttons {
                                display: flex;
                                gap: 6px;
                                
                                & .terminal-button {
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 50%;
                                    
                                    &.close {
                                        background-color: #FF5F56;
                                    }
                                    
                                    &.minimize {
                                        background-color: #FFBD2E;
                                    }
                                    
                                    &.maximize {
                                        background-color: #27C93F;
                                    }
                                }
                            }
                            
                            & .terminal-title {
                                flex: 1;
                                text-align: center;
                                font-size: 0.9em;
                                color: rgba(243, 206, 161, 0.6);
                                font-family: monospace;
                            }
                        }
                        
                        & .terminal-body {
                            padding: 15px;
                            font-family: monospace;
                            
                            & .terminal-line {
                                margin-bottom: 5px;
                                
                                & .terminal-prompt {
                                    color: #27C93F;
                                    margin-right: 5px;
                                }
                                
                                & .terminal-command {
                                    color: #f3cea1;
                                }
                            }
                            
                            & .terminal-output {
                                color: #FFFFFF;
                                margin-bottom: 15px;
                                margin-left: 15px;
                                border-left: 2px solid rgba(243, 206, 161, 0.3);
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
            
            & .about-description {
                max-width: 800px;
                
                & .description-text {
                    font-size: 1.1em;
                    line-height: 1.8;
                    margin-bottom: 20px;
                    color: rgba(243, 206, 161, 0.9);
                }
            }
            
            & .skills-section {
                margin-top: 20px;
                
                & .skills-title {
                    font-size: 1.8em;
                    font-weight: 300;
                    margin-bottom: 30px;
                    background: linear-gradient(135deg, #f3cea1, #ffffff);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
                
                & .skills-container {
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    
                    & .skill-category {
                        
                        & .category-title {
                            font-size: 1.3em;
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            color: #f3cea1;
                            
                            & .title-icon {
                                font-size: 1.2em;
                            }
                        }
                        
                        & .skills-grid {
                            display: grid;
                            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                            gap: 15px;
                            
                            & .skill-item {
                                background: rgba(243, 206, 161, 0.07);
                                border: 1px solid rgba(243, 206, 161, 0.15);
                                border-radius: 8px;
                                padding: 15px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 10px;
                                transition: all 0.3s ease;
                                
                                &:hover {
                                    background: rgba(243, 206, 161, 0.1);
                                    border-color: rgba(243, 206, 161, 0.25);
                                    transform: translateY(-5px);
                                }
                                
                                & .skill-icon {
                                    font-size: 1.8em;
                                    color: #f3cea1;
                                    opacity: 0.8;
                                }
                                
                                & .skill-name {
                                    font-size: 0.95em;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .aboutPage {
        padding: 60px 20px;
        
        & .about-container {
            & .about-content {
                gap: 40px;
                
                & .about-header {
                    & .title-container {
                        & .about-title {
                            font-size: 2.5em;
                        }
                    }
                    
                    & .profile-container {
                        flex-direction: column;
                        align-items: center;
                        
                        & .profile-image-container {
                            width: 200px;
                            height: 200px;
                        }
                    }
                }
                
                & .about-description {
                    & .description-text {
                        font-size: 1em;
                    }
                }
                
                & .skills-section {
                    & .skills-title {
                        font-size: 1.6em;
                    }
                    
                    & .skills-container {
                        gap: 30px;
                        
                        & .skill-category {
                            & .skills-grid {
                                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .aboutPage {
        padding: 40px 15px;
        
        & .about-container {
            & .about-content {
                gap: 30px;
                
                & .about-header {
                    & .title-container {
                        & .about-title {
                            font-size: 2em;
                        }
                    }
                    
                    & .profile-container {
                        & .profile-image-container {
                            width: 180px;
                            height: 180px;
                        }
                        
                        & .terminal-container {
                            min-width: 100%;
                        }
                    }
                }
                
                & .about-description {
                    & .description-text {
                        font-size: 0.95em;
                    }
                }
                
                & .skills-section {
                    & .skills-title {
                        font-size: 1.5em;
                    }
                    
                    & .skills-container {
                        gap: 25px;
                        
                        & .skill-category {
                            & .category-title {
                                font-size: 1.2em;
                            }
                            
                            & .skills-grid {
                                grid-template-columns: repeat(2, 1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}