.projectsPage {
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 9;
    padding: 120px 0;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle at center center, rgba(50, 30, 15, 0.3), transparent 70%);
        pointer-events: none;
        z-index: -10;
    }

    & .allContent {
        width: 100%;
        max-width: 1800px;
        padding: 0 40px;
        display: grid;
        gap: 50px;

        & .heading {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-bottom: 20px;

            & h2 {
                font-size: 3em;
                font-weight: 300;
                background: linear-gradient(135deg, #f3cea1, #ffffff);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
            }

            & p {
                font-size: 1.2em;
                font-weight: 300;
                color: rgba(243, 206, 161, 0.7);
            }
            
            & .category-filters {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px;
                margin-top: 20px;
                
                & .filter-btn {
                    padding: 8px 16px;
                    border-radius: 40px;
                    background: rgba(243, 206, 161, 0.05);
                    border: 1px solid rgba(243, 206, 161, 0.1);
                    color: rgba(243, 206, 161, 0.8);
                    font-size: 0.9em;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    
                    &:hover {
                        background: rgba(243, 206, 161, 0.1);
                    }
                    
                    &.active {
                        background: rgba(243, 206, 161, 0.2);
                        border-color: rgba(243, 206, 161, 0.3);
                        color: #f3cea1;
                    }
                }
            }
        }

        & .projects {
            & .projects-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
                gap: 30px;
                
                & .projectBox {
                    cursor: pointer;
                    overflow: hidden;
                    border-radius: 10px;
                    
                    & .projectCon {
                        width: 100%;
                        aspect-ratio: 4/3;
                        background-color: #001326;
                        border-radius: 10px;
                        overflow: hidden;
                        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
                        border: 1px solid rgba(243, 206, 161, 0.1);
                        transition: transform 0.3s ease-out;
                        
                        & .proj-img {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            background-size: cover;
                            background-position: center;
                            overflow: hidden;
                            
                            & .overlay {
                                position: absolute;
                                inset: 0;
                                background: linear-gradient(to top, rgba(0, 19, 38, 0.9) 0%, rgba(0, 19, 38, 0.5) 50%, rgba(0, 19, 38, 0.3) 100%);
                                z-index: 1;
                            }
                            
                            & .projectInfo {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                padding: 20px;
                                z-index: 2;
                                
                                & .pro-name {
                                    display: block;
                                    font-size: 1.5em;
                                    font-weight: 500;
                                    margin-bottom: 10px;
                                    color: #f3cea1;
                                }
                                
                                & .project-tags {
                                    display: flex;
                                    gap: 10px;
                                    flex-wrap: wrap;
                                    
                                    & .pro-lang, & .pro-status {
                                        padding: 5px 12px;
                                        border-radius: 20px;
                                        font-size: 0.8em;
                                        font-weight: 500;
                                    }
                                    
                                    & .pro-lang {
                                        background: rgba(243, 206, 161, 0.15);
                                        color: #f3cea1;
                                    }
                                    
                                    & .pro-status {
                                        background: rgba(255, 215, 0, 0.15);
                                        color: #ffd700;
                                    }
                                }
                            }
                            
                            & .view {
                                position: absolute;
                                top: 20px;
                                right: 20px;
                                background: rgba(243, 206, 161, 0.15);
                                color: #f3cea1;
                                padding: 8px 15px;
                                border-radius: 20px;
                                z-index: 2;
                                font-size: 0.9em;
                                
                                & span {
                                    display: inline-block;
                                    margin-left: 5px;
                                    transition: transform 0.3s ease;
                                }
                                
                                &:hover span {
                                    transform: translateX(3px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .project-dialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
        max-width: 90vw;
        width: 1000px;
        max-height: 90vh;
        z-index: 100;
        
        &::backdrop {
            background-color: rgba(0, 0, 0, 0.85);
            backdrop-filter: blur(5px);
        }
        
        &.closed {
            display: none;
            opacity: 0;
            visibility: hidden;
        }
        
        & .dialog-content {
            width: 100%;
            height: 100%;
            max-height: 90vh;
            overflow-y: auto;
            background-color: #001326;
            border-radius: 10px;
            border: 1px solid rgba(243, 206, 161, 0.2);
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
            
            & .header {
                display: flex;
                justify-content: flex-end;
                padding: 15px;
                position: sticky;
                top: 0;
                z-index: 10;
                background: rgba(0, 19, 38, 0.8);
                backdrop-filter: blur(5px);
                
                & .closeBtn {
                    background: rgba(243, 206, 161, 0.1);
                    color: #f3cea1;
                    border: none;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    
                    &:hover {
                        background: rgba(243, 206, 161, 0.2);
                        transform: rotate(90deg);
                    }
                    
                    & svg {
                        font-size: 1.5em;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .projectsPage {
        & .allContent {
            & .heading {
                & h2 {
                    font-size: 2.5em;
                }
            }
            
            & .projects {
                & .projects-grid {
                    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .projectsPage {
        padding: 80px 0;
        
        & .allContent {
            padding: 0 20px;
            gap: 30px;
            
            & .heading {
                & h2 {
                    font-size: 2em;
                }
                
                & p {
                    font-size: 1em;
                }
                
                & .category-filters {
                    & .filter-btn {
                        padding: 6px 12px;
                        font-size: 0.8em;
                    }
                }
            }
            
            & .projects {
                & .projects-grid {
                    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                    gap: 20px;
                }
            }
            
            & .project-dialog {
                & .dialog-content {
                    width: 95%;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .projectsPage {
        & .allContent {
            gap: 30px;
            
            & .heading {
                & h2 {
                    font-size: 2em;
                }
                
                & p {
                    font-size: 1em;
                }
                
                & .category-filters {
                    & .filter-btn {
                        padding: 6px 12px;
                        font-size: 0.8em;
                    }
                }
            }
            
            & .projects {
                & .projects-grid {
                    grid-template-columns: 1fr;
                    gap: 20px;
                }
                
                & .projectBox {
                    & .projectCon {
                        aspect-ratio: 16/9;
                    }
                }
            }
        }
        
        & .project-dialog {
            & .dialog-content {
                width: 95vw;
                max-height: 95vh;
            }
        }
    }
}