@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:wght@300;400;500;600&display=swap');

.mainPage {
  // max-width: 1800px;
  width: 100%;
  min-height: 3400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #000C18;
  overflow: hidden;
  
  .particles {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
    
    .particle {
      position: absolute;
      width: 2px;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      animation: particles 15s infinite linear;
      
      @for $i from 1 through 25 {
        &:nth-child(#{$i}) {
          $size: random(4) + 1px;
          width: $size;
          height: $size;
          top: random(100) * 1%;
          left: random(100) * 1%;
          animation-delay: random(10) * -1s;
          animation-duration: random(20) + 10s;
          opacity: random(10) * 0.1;
        }
      }
    }
  }
  
  .hero-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding-top: 100px;
    align-items: center;
    z-index: 2;
    
    .hero-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 0 20px;
      max-width: 1200px;
      
      .hero-text-container {
        position: relative;
        z-index: 2;
      }
      
      .hero-title {
        font-size: 3.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        color: #ffffff;
        
        .name-highlight {
          color: #FFD700;
          font-weight: 700;
          position: relative;
          display: inline-block;
          font-family: Caveat, sans-serif;
          transform: translateY(-5px);
        }
      }
      
      .hero-subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.5em;
        font-size: 1.5rem;
        color: #f3cea1;
        margin-bottom: 2rem;
        
        .typed-text {
          display: inline;
        }
        
        .cursor {
          display: inline-block;
          width: 3px;
          height: 1.2em;
          background-color: #f3cea1;
          margin-left: 5px;
          vertical-align: middle;
          
          &.blinking {
            animation: blink 1s step-end infinite;
          }
        }
      }
      
      .social-links {
        display: flex;
        gap: 1.5rem;
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: rgba(243, 206, 161, 0.1);
          color: #f3cea1;
          font-size: 1.3rem;
          transition: all 0.3s ease;
          
          &:hover {
            background-color: rgba(243, 206, 161, 0.2);
            transform: translateY(-5px);
          }
        }
      }
    }
    
    .scroll-indicator {
      position: absolute;
      bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      color: #f3cea1;
      
      .scroll-icon {
        font-size: 1.5rem;
        animation: bounce 2s infinite;
      }
      
      .scroll-text {
        font-size: 0.9rem;
        letter-spacing: 2px;
        opacity: 0.8;
      }
    }
  }
  
  .room-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
  }
  
  .about-container {
    position: relative;
    z-index: 2;
    margin-top: 100vh;
    width: 100%;
  }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
}

@keyframes particles {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh) translateX(100px);
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .mainPage {
    .hero-wrapper {
      .hero-content {
        .hero-title {
          font-size: 2.8rem;
        }
        
        .hero-subtitle {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .mainPage {
    .hero-wrapper {
      .hero-content {
        .hero-title {
          font-size: 2.2rem;
        }
        
        .hero-subtitle {
          font-size: 1rem;
        }
        
        .social-links {
          a {
            width: 40px;
            height: 40px;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .mainPage {
    .hero-wrapper {
      .hero-content {
        .hero-title {
          font-size: 1.8rem;
        }
        
        .hero-subtitle {
          font-size: 0.9rem;
        }
        
        .social-links {
          gap: 1rem;
          
          a {
            width: 36px;
            height: 36px;
            font-size: 1rem;
          }
        }
      }
    }
  }
}