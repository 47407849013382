.projectPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.loading {
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        & .loader {
            width: 50px;
            height: 50px;
            border: 3px solid rgba(243, 206, 161, 0.2);
            border-radius: 50%;
            border-top-color: #f3cea1;
            animation: spin 1s ease-in-out infinite;
        }
        
        @keyframes spin {
            to { transform: rotate(360deg); }
        }
    }

    & .projectContainer {
        flex-grow: 1;
        max-width: 1100px;
        width: 100%;
        padding: 10px 40px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        line-height: 1.6;
        
        & .project-title {
            font-size: 2.8em;
            font-weight: 300;
            margin-bottom: 10px;
            text-align: center;
            color: #f3cea1;
        }
        
        & .project-tags {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;
            
            & .project-lang, & .project-status {
                padding: 6px 20px;
                border-radius: 50px;
                font-size: 0.9rem;
                background-color: rgba(243, 206, 161, 0.15);
                color: #f3cea1;
                font-weight: 500;
            }
        }
        
        & .project-actions {
            display: flex;
            gap: 15px;
            margin: 5px 0;
            
            & .action-button {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 8px 20px;
                border-radius: 50px;
                font-size: 0.95rem;
                text-decoration: none;
                color: #f3cea1;
                font-weight: 500;
                transition: all 0.3s ease;
                
                &.demo-link {
                    background-color: rgba(243, 206, 161, 0.2);
                    border: 1px solid rgba(243, 206, 161, 0.3);
                    
                    &:hover {
                        background-color: rgba(243, 206, 161, 0.3);
                    }
                }
                
                &.github-link {
                    background-color: transparent;
                    border: 1px solid rgba(243, 206, 161, 0.3);
                    
                    &:hover {
                        background-color: rgba(243, 206, 161, 0.1);
                    }
                }
            }
        }
        
        & .imgsContainer {
            width: 100%;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            aspect-ratio: 16/9;
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
            margin: 20px 0;
            border: 1px solid rgba(243, 206, 161, 0.1);
            background-color: #000C18;
            
            & .project-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-position: center;
                z-index: 2;
                
                &.contain-image {
                    object-fit: contain;
                    padding: 0;
                }
                
                &.cover-image {
                    object-fit: cover;
                }
            }
            
            & .image-loading {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                
                & .loader {
                    width: 40px;
                    height: 40px;
                    border: 3px solid rgba(243, 206, 161, 0.2);
                    border-radius: 50%;
                    border-top-color: #f3cea1;
                    animation: spin 1s ease-in-out infinite;
                }
            }
            
            & .image-controls {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
                display: flex;
                justify-content: space-between;
                padding: 0 20px;
                pointer-events: none;
                
                & .img-nav-btn {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background: rgba(0, 0, 0, 0.5);
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border: none;
                    font-size: 1.2em;
                    pointer-events: auto;
                    transition: all 0.2s ease;
                    opacity: 0.7;
                    align-self: center;
                    
                    &:hover {
                        background: rgba(0, 0, 0, 0.7);
                        opacity: 1;
                    }
                    
                    &:disabled {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }
        
        & .project-description {
            color: #e0e0e0;
            font-size: 1rem;
            line-height: 1.6;
            text-align: justify;
            
            & p {
                margin-bottom: 1rem;
            }
        }
    }
}

@media (max-width: 768px) {
    .projectPage {
        & .projectContainer {
            padding: 10px 20px 40px;
            
            & .project-title {
                font-size: 2em;
            }
            
            & .imgsContainer {
                & .image-controls {
                    & .img-nav-btn {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .projectPage {
        & .projectContainer {
            padding: 10px 15px 30px;
            
            & .project-title {
                font-size: 1.8em;
            }
            
            & .project-tags {
                flex-wrap: wrap;
                justify-content: center;
            }
            
            & .project-actions {
                flex-direction: column;
                align-items: center;
            }
            
            & .imgsContainer {
                aspect-ratio: 3/4;
                
                & .image-controls {
                    & .img-nav-btn {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
            
            & .project-description {
                font-size: 0.9em;
            }
        }
    }
}