@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    scroll-behavior: smooth;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

html {
    overflow-x: hidden;
}

body {
    font-size: 1rem;
    color: #f3cea1;
    background-color: #000C18;
}

::-moz-selection {
    color: rgb(0, 0, 0);
    background-color: #FFD700;
}

::selection {
    color: rgb(0, 0, 0);
    background-color: #FFD700;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
// }

/* Handle */
::-webkit-scrollbar-thumb {
    background: #f3cea1;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FFD700;
}

.app {
    background-color: #000C18;
    color: #f3cea1;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1700px) {
    body {
        font-size: 0.95rem;
    }
}

@media (max-width: 1350px) {
    body {
        font-size: 0.9rem;
    }
}

@media (max-width: 1000px) {
    body {
        font-size: 0.85rem;
    }
}

@media (max-width: 700px) {
    body {
        font-size: 0.8rem;
    }
}

// @media (max-width: 500px) {
//     body {
//         font-size: 0.65rem;
//     }
// }