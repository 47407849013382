.contact {
    position: relative;
    width: 100%;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    z-index: 100;

    & .footer-content {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        position: relative;

        & .contact-section {
            text-align: center;
            margin-bottom: 50px;

            & .contact-title {
                font-size: 2.5em;
                font-weight: 300;
                margin-bottom: 15px;
                background: linear-gradient(135deg, #f3cea1, #ffffff);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
            }

            & .contact-subtitle {
                font-size: 1.1em;
                font-weight: 300;
                margin-bottom: 30px;
                color: rgba(243, 206, 161, 0.8);
            }
        }

        & .social-contact {
            width: 100%;
            display: flex;
            justify-content: center;

            & .contact-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 20px;
                list-style: none;
                padding: 0;
                margin: 0;

                & li {
                    & a {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 12px 20px;
                        background: rgba(243, 206, 161, 0.05);
                        border: 1px solid rgba(243, 206, 161, 0.15);
                        border-radius: 50px;
                        color: #f3cea1;
                        text-decoration: none;
                        transition: all 0.3s ease;

                        &:hover {
                            background: rgba(243, 206, 161, 0.1);
                            transform: translateY(-5px);
                            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
                        }

                        & .icon {
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }
    }

    & .scroll-to-top {
        position: absolute;
        right: 40px;
        bottom: 30px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: rgba(243, 206, 161, 0.1);
        border: 1px solid rgba(243, 206, 161, 0.2);
        color: #f3cea1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background: rgba(243, 206, 161, 0.2);
            transform: translateY(-5px);
        }
    }

    & .copyright-section {
        width: 100%;
        text-align: center;
        margin-top: 20px;

        & .copyright {
            font-size: 0.9em;
            color: rgba(243, 206, 161, 0.6);
            display: flex;
            flex-direction: column;
            gap: 5px;

            & .copyright-year {
                font-size: 0.9em;
            }
        }
    }
}

@media (max-width: 900px) {
    .contact {
        padding: 60px 20px 30px;

        & .footer-content {
            & .contact-section {
                & .contact-title {
                    font-size: 2.2em;
                }

                & .contact-subtitle {
                    font-size: 1em;
                }
            }

            & .social-contact {
                & .contact-list {
                    gap: 15px;

                    & li {
                        & a {
                            padding: 10px 15px;
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .contact {
        padding: 50px 15px 25px;

        & .footer-content {
            & .contact-section {
                margin-bottom: 30px;

                & .contact-title {
                    font-size: 1.8em;
                }

                & .contact-subtitle {
                    font-size: 0.9em;
                }
            }

            & .social-contact {
                & .contact-list {
                    flex-direction: column;
                    gap: 12px;
                    width: 100%;
                    max-width: 280px;
                    margin: 0 auto;

                    & li {
                        width: 100%;

                        & a {
                            width: 100%;
                            justify-content: center;
                        }
                    }
                }
            }
        }

        & .scroll-to-top {
            width: 40px;
            height: 40px;
            right: 20px;
            bottom: 20px;
        }
    }
}