.loadingPage {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000C18;
    color: #f3cea1;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    visibility: visible;
    opacity: 1;
    overflow: hidden;

    .particles {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: 0;
        
        .particle {
          position: absolute;
          width: 2px;
          height: 2px;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          animation: particles 15s infinite linear;
          
          @for $i from 1 through 25 {
            &:nth-child(#{$i}) {
              $size: random(4) + 1px;
              width: $size;
              height: $size;
              top: random(100) * 1%;
              left: random(100) * 1%;
              animation-delay: random(10) * -1s;
              animation-duration: random(20) + 10s;
              opacity: random(10) * 0.1;
            }
          }
        }
      }

    .loading-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;
        width: 100%;
        max-width: 500px;
        padding: 0 20px;
    }

    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        *{
            font-family: Caveat, sans-serif;
        }

        .code-brackets {
            font-size: 3rem;
            font-weight: 300;
            color: #FFD700;
            
            &.left {
                margin-right: 5px;
            }
            
            &.right {
                margin-left: 5px;
            }
        }
        
        .logo-text {
            font-size: 3.5rem;
            font-weight: 300;
            background: linear-gradient(135deg, #f3cea1, #ffffff);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }
    }
    
    .loader-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    
    .progress-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    
    .progress-bar {
        width: 100%;
        height: 4px;
        background-color: rgba(243, 206, 161, 0.1);
        border-radius: 4px;
        overflow: hidden;
        
        .progress-fill {
            height: 100%;
            background: linear-gradient(90deg, #f3cea1, #FFD700);
            border-radius: 4px;
        }
    }
    
    .progress-text {
        align-self: flex-end;
        font-size: 0.9rem;
        font-weight: 300;
        color: rgba(243, 206, 161, 0.8);
    }
    
    .loading-message {
        font-size: 1.1rem;
        color: rgba(243, 206, 161, 0.9);
        font-weight: 300;
        margin-top: 10px;
        
        .dot-animation {
            display: inline-block;
            animation: dotAnimation 1.5s infinite;
        }
    }

    @keyframes particles {
        0% {
          transform: translateY(0) translateX(0);
          opacity: 1;
        }
        70% {
          opacity: 1;
        }
        100% {
          transform: translateY(-100vh) translateX(100px);
          opacity: 0;
        }
      }
    
    @keyframes float {
        0% {
            transform: translateY(0) translateX(0);
            opacity: 0;
        }
        
        10% {
            opacity: 1;
        }
        
        90% {
            opacity: 1;
        }
        
        100% {
            transform: translateY(#{random(100) - 50}px) translateX(#{random(100) - 50}px);
            opacity: 0;
        }
    }
    
    @keyframes dotAnimation {
        0%, 20% {
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        60% {
            opacity: 1;
        }
        80%, 100% {
            opacity: 0;
        }
    }
    
    @media (max-width: 768px) {
        .logo-container {
            .code-brackets {
                font-size: 2.5rem;
            }
            
            .logo-text {
                font-size: 3rem;
            }
        }
        
        .loading-message {
            font-size: 1rem;
        }
    }
    
    @media (max-width: 480px) {
        .logo-container {
            .code-brackets {
                font-size: 2rem;
            }
            
            .logo-text {
                font-size: 2.5rem;
            }
        }
    }
}